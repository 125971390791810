/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:984a1e1d-eb8d-4a66-8009-5140176c68df",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_mLEyDaV36",
    "aws_user_pools_web_client_id": "6f2vnelgfc02haq8ubks4jpbap",
    "oauth": {},
    "aws_user_files_s3_bucket": "agentpower4115435-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
